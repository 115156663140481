import { useCallback, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { Form } from '@unform/web'
import debounce from 'lodash/debounce'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import { Button } from '~/components/Buttons'
import { CreditCardForm } from '~/components/CreditCardForm'
import Panel from '~/components/Panel'
import { FormGroup, Input, InputMoney, Label, Radio } from '~/components/unform'
import InputCpfCnpj from '~/components/unform/Input/InputCpfCnpj'
import {
  formatMoneyV2,
  getEmail,
  handleValidationsErrors,
  history,
  unformatMoney
} from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { paymentMethodsOptions } from '~/modules/retailMedia/components/PaymentsTable/constants'
import WalletHeader from '~/modules/retailMedia/components/WalltetHeader'
import { formatIndustryRecharge } from '~/modules/retailMedia/dtos/industry/wallet/recharge'
import { rechargeIndustryWallet } from '~/modules/retailMedia/store/industry/wallet/actions'
import AddressFields from '~/pages/locations/_elements/FormLocation/Address'

import { PersonForm } from './components/PersonForm'
import {
  FormIndustryRechargeSchema,
  FormAddress,
  FormPerson,
  FormAdditionalBoleto
} from './validation'

import * as S from './styles'

const WalletRecharge = () => {
  const publisherId = useQueryParams().get('rmid')
  const { id } = useParams()
  const { hasRecharge } = useAppInfo()
  // temporary - Hide panvel
  // const canRecharge = publisherId !== 'eb729f60-c9a2-11ed-bf0e-0a84c5f05565'

  const debounceDelay = 300

  // const [publisherId, setPublisherId] = useState('')
  const [cardBrand, setCardBrand] = useState('' as string)
  const [paymentMethod, setPaymentMethod] = useState('credit_card')
  // const [paymentMethod, setPaymentMethod] = useState(
  //   canRecharge ? 'credit_card' : ('boleto' as PaymentMethod)
  // )
  const [amountStepIsValid, setAmountStepIsValid] = useState(false)
  const [cardStepIsValid, setCardStepIsValid] = useState(false)
  const [addressStepIsValid, setAddressStepIsValid] = useState(false)
  const [personStepIsValid, setPersonStepIsValid] = useState(false)
  const [additionalBoletoStepIsValid, setAdditionalBoletoStepIsValid] =
    useState(false)
  const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [paymentRefused, setPaymentRefused] = useState(false)

  const formRef = useRef(null)
  const dispatch = useDispatch()

  const { rechargeMinValue, lengthCentsCurrency } = useAppInfo()

  const onChangeAmount = debounce((data: { floatValue: number }) => {
    const valueDailyBudget = data.floatValue
    const amount = lengthCentsCurrency
      ? Number(valueDailyBudget) / 100
      : Number(valueDailyBudget)

    setAmountStepIsValid(amount >= rechargeMinValue)
  }, debounceDelay)

  const onChangeCardForm = ({
    isValid,
    brand
  }: {
    isValid: boolean
    brand: string
  }) => {
    setCardStepIsValid(isValid)
    setCardBrand(brand)
  }

  const onChangeForm = debounce(e => {
    const shouldValidateCard = paymentMethod === 'credit_card'

    const validators = [
      {
        schema: FormAddress,
        setValid: setAddressStepIsValid,
        shouldValidate: shouldValidateCard || paymentMethod === 'boleto'
      },
      {
        schema: FormPerson,
        setValid: setPersonStepIsValid,
        shouldValidate: paymentMethod !== 'boleto'
      },
      {
        schema: FormAdditionalBoleto,
        setValid: setAdditionalBoletoStepIsValid,
        shouldValidate: paymentMethod === 'boleto'
      }
    ]
    const { value, name } = e.target || e

    const data = { ...formRef.current.getData(), [name]: value }

    const valid = validators
      .map(({ schema, setValid, shouldValidate }) => {
        setValid(false)
        try {
          if (!shouldValidate) return true

          schema.validateSync(data, { abortEarly: false })
          setValid(true)
          return true
        } catch (error) {
          return false
        }
      })
      .every(Boolean)

    if (shouldValidateCard) setFormIsReadyToSubmit(valid && cardStepIsValid)
    else setFormIsReadyToSubmit(valid)
  }, debounceDelay)

  const { t } = useAPPTranslation()

  const onSubmit = useCallback(
    (data: FormIndustryRecharge) => {
      if (!amountStepIsValid) {
        toastr.error(
          t('rm:wallet.recharge.error.minimumValue.title'),
          t('rm:wallet.recharge.error.minimumValue.message', {
            value: formatMoneyV2({ value: rechargeMinValue })
          })
        )

        setLoading(false)
      }

      setLoading(true)
      setPaymentRefused(false)
      const { amount } = data

      const valueAmount = amount?.numAsString
      const value = lengthCentsCurrency
        ? Number(valueAmount) / 100
        : Number(valueAmount)

      data.amount = value

      data.publisher_id = publisherId

      try {
        FormIndustryRechargeSchema[paymentMethod].validateSync(data, {
          abortEarly: false
        })

        if (!cardStepIsValid && paymentMethod === 'credit_card')
          throw new Error(t('rm:invalidCard'))
      } catch (error) {
        handleValidationsErrors(error, formRef)

        setLoading(false)
        return
      }

      data.card_brand = cardBrand

      const body = formatIndustryRecharge(data)

      const handleRefused = () => {
        toastr.error(
          t('rm:error'),
          t('rm:recharge.transaction.instructions.paymentError')
        )

        setLoading(false)
        setPaymentRefused(true)
      }

      dispatch(
        rechargeIndustryWallet({
          body,
          showSuccessCallback: ({ data: response }) =>
            [
              'payment_approved',
              'waiting_payment',
              'payment_authorized'
            ].includes(response.status),
          onError: handleRefused,
          onSuccess: ({ data: response, status: statusCode }) => {
            const isSuccess = [
              'payment_approved',
              'payment_authorized',
              'waiting_payment'
            ].includes(response.status)
            if (isSuccess) {
              history.push(
                `/retail-media/wallet/${id}/transaction/${response.id}?rmid=${publisherId}`
              )
            }

            const isRefused = response.status === 'payment_refused'

            if (isRefused) {
              handleRefused()
            }

            if (statusCode === 204) {
              history.push(`/retail-media/wallet/${id}?rmid=${publisherId}`)
              toastr.success(t('rm:success'), t('rm:boleto.availableSoon'))
            }
          }
        })
      )
    },
    [
      amountStepIsValid,
      lengthCentsCurrency,
      publisherId,
      cardBrand,
      dispatch,
      t,
      rechargeMinValue,
      paymentMethod,
      cardStepIsValid,
      id
    ]
  )

  return (
    <>
      <WalletHeader
        showActions={false}
        title={t('rm:wallet.recharge')}
        labelBreadcrumb={t('rm:wallet.backTo')}
        backTo={`/retail-media/wallet/${id}/movimentations?rmid=${publisherId}`}
      />

      {!hasRecharge && (
        <AlertInfo
          template="danger"
          text={t('rm:wallet.recharge.automatic.notAvailable')}
        />
      )}

      {hasRecharge && (
        <>
          <S.Container>
            <Form
              onSubmit={onSubmit}
              onChange={onChangeForm}
              ref={formRef}
              initialData={{
                payment_method: 'credit_card'
              }}
            >
              {publisherId && (
                <Panel
                  title={t('rm:Value')}
                  description={t('rm:value.set')}
                  className="form-grid"
                  iconLeft={<BadgeIsValid isValid={amountStepIsValid} />}
                >
                  <FormGroup>
                    <InputMoney
                      name="amount"
                      placeholder={formatMoneyV2({ value: rechargeMinValue })}
                      onChange={onChangeAmount}
                      helpText={t('rm:minimumValueOf', {
                        value: formatMoneyV2({ value: rechargeMinValue })
                      })}
                    />
                  </FormGroup>
                </Panel>
              )}

              {/* Payment method */}
              {publisherId && (
                <Panel
                  title={t('rm:Payment')}
                  description={t('rm:payment.method')}
                  iconLeft={
                    <BadgeIsValid
                      isValid={paymentMethod === 'pix' || cardStepIsValid}
                    />
                  }
                >
                  <FormGroup className="span2">
                    <Radio
                      options={paymentMethodsOptions}
                      name="payment_method"
                      required
                      itemSize="small"
                      layout="grid"
                      onChange={e => setPaymentMethod(e.target.value)}
                    />
                  </FormGroup>

                  {paymentMethod && <hr className="divider" />}

                  {/* Card form */}
                  {paymentMethod === 'credit_card' && (
                    <CreditCardForm onChange={onChangeCardForm} />
                  )}

                  {/* Pix info */}
                  {paymentMethod === 'pix' && (
                    <div style={{ padding: '1rem' }}>
                      <li>
                        <Trans
                          i18nKey="rm:pixInfo.clickRechargeButton"
                          defaults='Após clicar no botão "<strong>Fazer recarga</strong>", o código <strong>PIX</strong> estará disponível para você efetuar o pagamento em <strong>até 1 hora</strong>.'
                          components={{ strong: <strong /> }}
                        />
                      </li>
                      <li>{t('rm:pixInfo.instantPaymentIdentification')}</li>
                      <li>{t('rm:pixInfo.paymentNotMade')}</li>
                      <li>
                        <Trans
                          i18nKey="rm:pixInfo.cannotChangeAmount"
                          defaults="É importante lembrar que, após a finalização, não será possível alterar o valor dessa recarga. Portanto, <strong>tenha certeza do valor que deseja adicionar</strong>."
                          components={{ strong: <strong /> }}
                        />
                      </li>
                    </div>
                  )}

                  {/* boleto info */}
                  {paymentMethod === 'boleto' && (
                    <div style={{ padding: '1rem' }}>
                      <li>{t('rm:boletoInfo.email', { email: getEmail() })}</li>
                      <li>{t('rm:boletoInfo.5days')}</li>
                    </div>
                  )}
                </Panel>
              )}

              {/* Address form */}
              {(paymentMethod === 'credit_card' ||
                paymentMethod === 'boleto') && (
                <AddressFields
                  formRef={formRef}
                  labelItem="de cobrança"
                  descriptionPanel={t('rm:payment.aditionalInfo')}
                  hideCoordinates
                  allRequired
                  isCollapsible={false}
                  showBadgeValidate
                  badgeIsValid={addressStepIsValid}
                />
              )}

              {/* Person form */}
              {paymentMethod && paymentMethod !== 'boleto' && (
                <PersonForm personStepIsValid={personStepIsValid} />
              )}

              {paymentMethod && paymentMethod === 'boleto' && (
                <Panel
                  title={t('rm:additionalInfo')}
                  description={t('rm:payment.responsible')}
                  className="form-grid"
                  iconLeft={
                    <BadgeIsValid isValid={additionalBoletoStepIsValid} />
                  }
                >
                  <div style={{ display: 'flex', gap: '1rem' }}>
                    <FormGroup>
                      <Label>Razão social</Label>
                      <Input
                        name="company_name"
                        label={t('rm:companyName')}
                        placeholder="Razão social"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>CNPJ</Label>
                      <InputCpfCnpj
                        id="social_id"
                        name="social_id"
                        placeholder="CNPJ"
                        required
                        initialData=""
                      />
                    </FormGroup>
                  </div>
                </Panel>
              )}

              {!!paymentRefused && (
                <AlertInfo
                  template="danger"
                  text={t('rm:payment.error.details')}
                  // text={`Houve um erro ao efetuar o pagamento. Revise os dados e tente novamente. Se o erro persistir, entre em contato com o suporte Newtail ou com a operadora do cartão. Cheque também o valor mínimo para recarga: ${formatMoneyV2({ value: rechargeMinValue })}.`}
                />
              )}

              <div className="form-default__buttons">
                <Button
                  type="submit"
                  text={t('rm:recharge')}
                  loading={loading}
                  size="large"
                  template="success"
                  disabled={!formIsReadyToSubmit || !amountStepIsValid}
                />
              </div>
            </Form>
          </S.Container>
        </>
      )}
    </>
  )
}

export default WalletRecharge
