import { isPublisher } from '~/helpers'

import AdvertiserTransactions from './AdvertiserTransactions'
import PublisherTransactions from './PublisherTransactions'

const WalletView: React.FC = () => {
  return isPublisher() ? <PublisherTransactions /> : <AdvertiserTransactions />
}

export default WalletView
